.icon--star {
  width: 50px;
  height: 50px;

  .icon-star__shape {
    fill: #fffef2;
  }

  &--clickable {
    cursor: pointer;
  }

  &--active {
    .icon-star__shape {
      fill: #f9e300;
    }
  }
}
