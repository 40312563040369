@keyframes loading {
  0% {
    transform: translateX(-100%);
    width: 100%;
  }
  100% {
    transform: translateX(200%);
    width: 50%;
  }
}

.loading-bar {
  height: 5px;
  overflow: hidden;

  &--inner {
    animation: loading 2000ms cubic-bezier(0.33, 0.99, 0.6, 1.01) infinite;
    background: linear-gradient(to left, white, blue, white);
    height: 100%;
  }
}
