.header {
  background-color: #000;
  padding: 20px 0;
}

.header nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header a {
  color: #ffc200;
  text-decoration: none;
  font-weight: bold;
  padding: 0 1em;

  &:hover {
    color: #ff9a00;
  }

  &:active {
    color: #ff7700;
  }

  &.active {
    color: #ff9a00;
  }
}
