@import "./variables.scss";

.lines {
  text-align: right;
}

.line {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin: 1px;
}

.cta-blue {
  background-color: $color-cta-blue;
  color: white;
}

.cta-brown {
  background-color: $color-cta-brown;
  color: white;
}

.cta-green {
  background-color: $color-cta-green;
  color: white;
}

.cta-grey {
  background-color: $color-cta-grey;
  color: white;
}

.cta-orange {
  background-color: $color-cta-orange;
  color: white;
}

.cta-pink {
  background-color: $color-cta-pink;
  color: white;
}

.cta-purple {
  background-color: $color-cta-purple;
  color: white;
}

.cta-red {
  background-color: $color-cta-red;
  color: white;
}

.cta-yellow {
  background-color: $color-cta-yellow;
  color: black;
}
