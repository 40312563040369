.station-list__item {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0.5em;
  color: black;
  text-decoration: none;
  margin: 0.75em 0;

  &__name {
    font-size: 1.1em;
    margin: 0;
  }

  &__distance {
    font-size: 0.85em;
    margin: 0;
  }
}
